<template>
  <div class="home container">
    <Carousel height="720px" theme="banner" />
    <div class="p20">
      <div class="search">
        <el-form :model="form" ref="form" class="search-form">
          <el-form-item prop="destination" class="destination">
            <el-input v-model="form.destination" placeholder="Destination"></el-input>
          </el-form-item>
          <div class="search-col2">
            <el-form-item prop="date" class="date-picker">
              <el-date-picker v-model="form.date" type="daterange" range-separator="~" start-placeholder="Check-in" end-placeholder="Check-out" align="center"> </el-date-picker>
            </el-form-item>
            <!-- <el-form-item prop="guest">
              <el-input-number v-model="form.guest" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
            </el-form-item>
            <el-form-item prop="room">
              <el-input-number v-model="form.room" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
            </el-form-item> -->
          </div>
          <div class="btn-search" @click="submitForm('form')">
            <img src="../assets/images/icon-search.svg" width="60" />
            <span>Search</span>
          </div>
        </el-form>
      </div>
      <div class="hotel-list">
        <div class="item">
          <div class="item-pics">
            <Carousel height="396px" />
          </div>
          <div class="item-content">
            <div class="item-head">
              <div class="tag">Hotel</div>
              <div class="title">QFOX LUXUARY</div>
            </div>
            <ul class="item-rooms">
              <li>
                <div class="title">Single Room</div>
                <div class="room-intro">
                  <div class="user">1</div>
                  <div class="bed">1</div>
                  <div class="bathroom">1</div>
                </div>
              </li>
              <li>
                <div class="title">Single Room</div>
                <div class="room-intro">
                  <div class="user">1</div>
                  <div class="bed">1</div>
                  <div class="bathroom">1</div>
                </div>
              </li>
              <li>
                <div class="title">Single Room</div>
                <div class="room-intro">
                  <div class="user">1</div>
                  <div class="bed">1</div>
                  <div class="bathroom">1</div>
                </div>
              </li>
            </ul>
            <div class="item-footer">
              <div class="price">JPY 10000 ~</div>
              <div class="btn btn-theme">BOOK NOW</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-pics">
            <Carousel height="396px" />
          </div>
          <div class="item-content">
            <div class="item-head">
              <div class="tag">Hotel</div>
              <div class="title">QFOX LUXUARY EXTENSION DIVISION XII CROWN HOTEL</div>
            </div>
            <ul class="item-rooms">
              <li>
                <div class="title">Single Room</div>
                <div class="room-intro">
                  <div class="user">1</div>
                  <div class="bed">1</div>
                  <div class="bathroom">1</div>
                </div>
              </li>
              <li>
                <div class="title">Single Room</div>
                <div class="room-intro">
                  <div class="user">1</div>
                  <div class="bed">1</div>
                  <div class="bathroom">1</div>
                </div>
              </li>
              <li>
                <div class="title">Single Room</div>
                <div class="room-intro">
                  <div class="user">1</div>
                  <div class="bed">1</div>
                  <div class="bathroom">1</div>
                </div>
              </li>
            </ul>
            <div class="item-footer">
              <div class="price">JPY 10000 ~</div>
              <div class="btn btn-theme">BOOK NOW</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-pics">
            <Carousel height="396px" />
          </div>
          <div class="item-content">
            <div class="item-head">
              <div class="tag">Villa</div>
              <div class="title">YOICHI V</div>
            </div>
            <ul class="item-rooms">
              <li>
                <div class="title">Master Room x 1</div>
              </li>
              <li>
                <div class="title">Twin Room x 2</div>
              </li>
            </ul>
            <div class="room-intro">
              <div class="user">6</div>
              <div class="sofa">1</div>
              <div class="kitchen">1</div>
              <div class="bed">1</div>
              <div class="bathroom">1</div>
              <div class="toilet">1</div>
            </div>
            <div class="item-footer">
              <div class="price">JPY 10000 ~</div>
              <div class="btn btn-theme">BOOK NOW</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pic-list activity">
        <div class="item" :style="`background-image: url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg')`">
          <div class="btn btn-theme">SNOWBOARD LESSON</div>
        </div>
        <div class="item">
          <div class="btn btn-theme">HIKING GUIDE</div>
        </div>
        <div class="item">
          <div class="btn btn-theme">DIVING LESSON</div>
        </div>
      </div>
      <div class="pic-list product">
        <div class="item">
          <div class="btn btn-theme2">BEVERAGE</div>
        </div>
        <div class="item">
          <div class="btn btn-theme2">HOTPOT DINNER</div>
        </div>
        <div class="item">
          <div class="btn btn-theme2">SNACK</div>
        </div>
      </div>
      <div class="pic-list service">
        <div class="item">
          <div class="btn btn-white">COURIER</div>
        </div>
        <div class="item">
          <div class="btn btn-white">AIRPORT PICK-UP</div>
        </div>
        <div class="item">
          <div class="btn btn-white">ROOM CLEANING</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'Home',
  components: {
    Carousel,
  },
  data() {
    return {
      form: {
        destination: '',
        date: '',
        guest: 1,
        room: 1,
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleChange(value) {
      console.log(value)
    },
  },
}
</script>
