<template>
  <el-carousel :height="height" :autoplay="false" arrow="always" :class="theme">
    <el-carousel-item>
      <div class="carousel-item video">
        <img src="../assets/pic/banner.jpg" />
        <div class="tools">
          <div class="left">
            <div class="fav"><img src="../assets/images/icon-fav.svg" width="60" /></div>
            <div class="share"><img src="../assets/images/icon-share.svg" width="60" /></div>
          </div>
          <div class="fullscreen"><img src="../assets/images/icon-fullscreen.svg" width="60" /></div>
        </div>
      </div>
    </el-carousel-item>
    <el-carousel-item>
      <div class="carousel-item video">
        <img src="../assets/pic/banner.jpg" />
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: 'Carousel',
  props: ['height', 'theme'],
}
</script>

<style scoped lang="scss">
.tools {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
  .left {
    display: flex;
    align-items: center;
  }
  .fav {
    margin-right: 30px;
  }
  .fav,
  .share,
  .fullscreen {
    cursor: pointer;
  }
}
</style>
