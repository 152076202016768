<template>
  <div class="container header">
    <div class="logo">
      <img src="../../assets/logo.svg" width="228" />
    </div>
    <div class="right">
      <div class="btn-login">
        <el-button type="text">Login</el-button>
      </div>
      <div class="btn btn-theme">Join</div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {}
  },
  components: {},
}
</script>

<style scoped lang="less"></style>
