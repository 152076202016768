<template>
  <div class="footer">
    <div class="container">
      <div class="title">Sample co., ltd</div>
      <div class="address">(〒110-0016) 2-30-7 Taito, Taito-ku, Tokyo, Japan</div>
      <div class="contact">
        <div class="phone"><img src="../../assets/images/icon-phone.svg" width="27" />+86 03-2394-3048</div>
        <div class="web"><img src="../../assets/images/icon-global.svg" width="30" />www.onestep.com</div>
        <div class="email"><img src="../../assets/images/icon-email.svg" width="30" />info@onestep.com</div>
      </div>
      <ul class="social">
        <li>
          <a href=""><img src="../../assets/images/social1.svg" height="50" /></a>
        </li>
        <li>
          <a href=""><img src="../../assets/images/social2.svg" height="50" /></a>
        </li>
        <li>
          <a href=""><img src="../../assets/images/social3.svg" height="34" /></a>
        </li>
        <li>
          <a href=""><img src="../../assets/images/social4.svg" height="40" /></a>
        </li>
        <li>
          <a href=""><img src="../../assets/images/social5.svg" height="50" /></a>
        </li>
        <li>
          <a href=""><img src="../../assets/images/social6.svg" height="50" /></a>
        </li>
        <li>
          <a href=""><img src="../../assets/images/social7.svg" height="50" /></a>
        </li>
        <li>
          <a href=""><img src="../../assets/images/social8.svg" /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  },
  components: {},
}
</script>

<style scoped lang="sass"></style>
