import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
      },
      {
        path: '/activity',
        name: 'Activity',
        component: () => import('../views/Activity.vue'),
      },
      {
        path: '/product',
        name: 'Product',
        component: () => import('../views/Product.vue'),
      },
      {
        path: '/hotel',
        name: 'Hotel',
        component: () => import('../views/Hotel.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
